import React from 'react'
import Bolt from 'resources/images/svgs/dieselalysis/bolt-bullet.svg';

const Benefits = () => {
  return (
    <>
        <div id="dsls-benefits">
            <div className="container-lg p-4 d-flex  justify-content-between flex-column flex-sm-row">
                <div className=" headline text-tradesmith-stamp d-flex "> 
                    <h1 className='text-primary big-text mb-0'>3</h1>
                    <div className='benefits-guarantee ps-1 ps-md-3'>
                        <h4 className='text-white mb-0'>Guaranteed</h4>
                        <h4 className='text-white mb-0'>Undeniable</h4>
                        <h4 className='text-white mb-0'>Benefits</h4>
                    </div>
                </div>
                <div className="right mt-4 mt-sm-0">
                    <div className="bolted d-flex align-items-center">
                        <img src={Bolt} className="img-fluid me-3" alt="bolt" />
                        <h4 className='mb-0 text-white fst-italic'>Extended Engine Life</h4>
                    </div>
                    <div className="bolted d-flex align-items-center">
                        <img src={Bolt} className="img-fluid me-3" alt="bolt" />
                        <h4 className='mb-0 text-white fst-italic'>Improved Combustion</h4>
                    </div>
                    <div className="bolted d-flex align-items-center">
                        <img src={Bolt} className="img-fluid me-3" alt="bolt" />
                        <h4 className='mb-0 text-white fst-italic'>Lower Emissions</h4>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export {Benefits}
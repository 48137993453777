import React from 'react'
import '../resources/styles/app.scss';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import 'resources/styles/app.scss';

import { Home } from 'pages/home';
import { Header } from 'components/_common';
import { Footer } from 'components/_common/footer';
import { Dieselalysis } from 'pages/dieselalysis';
import ScrollToTop from 'utils/scrollToTop';

export const App = () => {

  return (
    <>
      <BrowserRouter classNames="bg-dark">
      <ScrollToTop />
        <Header/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/home" element={<Home />}/>
          <Route path="/dieselalysis" element={<Dieselalysis />}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App;

import React from 'react'
import RecycleFuel from 'resources/images/svgs/dieselalysis/recycle-fuel-polishing.svg';

const FuelRecycle = () => {
  return (
    <>
        <div id="fuel-recycle" className="container-lg p-4 mt-3 mt-md-5">
          <div className="inner-wrap d-flex py-4">
            <div className="w-auto left d-flex align-items-start">
                  <img className='w-100 d-block img-fluid' src={RecycleFuel} alt="" />
              </div>
              <div className="w-100 right ps-3 ps-md-5 d-flex align-items-center">
                <p className='text-white medium-text mb-0 fw-500 '><span className='text-primary'>Dieselalysis</span>&#8482;, is our own brand of “Fuel Polishing”-the technical process of removing water, sediment and microbial contamination from diesel fuel, hence the word, derived from “Diesel Fuel” and “Dialysis”.</p>
              </div>
            </div>
        </div>
    </>
  )
}

export { FuelRecycle}
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const CustomModal = (props) => {
    const { 
        show, 
        content, 
        title, 
        close, 
        className, 
        id, 
        header = null, 
        noHeader = false,
        footer = null, 
        noFooter = false,
        ...restOfProps
    } = props;

  return (
    <div>
        <Modal id={id} className={`cx-modal ${className}`} show={show} {...restOfProps} onHide={close}>

            {header || noHeader ? header:
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
            }

            <Modal.Body>{content}</Modal.Body>
            
            {footer || noFooter ? footer:
                <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={close}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            }

        </Modal>

    </div>
  )
}

export {CustomModal}
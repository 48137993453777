import React from 'react'
import Sponsored from 'resources/images/general/sponsored.jpg';
import Shirt from 'resources/images/general/shirt.jpg';
export const BlazingPassion = () => {
  return (
    <div className='blazing-passion container-lg d-flex flex-column-reverse  flex-lg-row p-4 '>
                <div className="left slider d-flex w-100 flex-column flex-sm-row justify-content-between flex-wrap">
                        <div className="slide w-100 d-flex align-items-center">
                                <div className="img-wrap mb-4">
                                        <img src={Sponsored} className="img-fluid" alt="jonathan sepulveda, mma fighter with black gloves and white trunks" />
                                </div>
                        </div>
                        <div className="slide w-100 d-flex align-items-center px-3 pt-2">
                                <div className="img-wrap mb-4">
                                        <img src={Shirt} className="img-fluid" alt="black unisex shirt, diesel junkie print" />
                                </div>
                        </div>
                </div>
                <div className="right w-100 ps-0 ps-lg-5 mb-0 mb-5 mb-md-0">
                        <h2 className='text-primary mb-4'>Blazing Passion</h2>
                        <h2 className='h1 text-robto-condensed text-gray big-text fst-italic text-roboto-condensed fw-900 '>Diesel Powered Beasts Vol. 01 <br /> is finally out!</h2>
                        <p className='fs-5 text-white mt-3'>Do you have the guts to wear it?</p>
                        <a type='button' href='https://dieseljunkiepr.bigcartel.com/' target="_blank"   className="btn btn-warning rounded-pill fs-700 fst-italic fw-900 fs-5 px-5 py-2 text-roboto-condensed mt-3 mb-2 mb-md-0" rel="noreferrer">Shop now!</a>
        </div>
        </div>
        )
}

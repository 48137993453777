import React from 'react'
import PreventiveMaintenance from 'resources/images/svgs/preventive-maintenance.svg';
import TroubleShooting from 'resources/images/svgs/troubleshooting.svg';
import FuelPolishing from 'resources/images/svgs/fuel-polishing.svg';
import PaintCorrosionTreatment from 'resources/images/svgs/corrosion-treatment.svg';
import MaintenancePlans from 'resources/images/svgs/maintenance-plan.svg';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
export const Deck = () => {

        const servicesDeck = [
                {
                        name: 'PREVENTIVE MAINTENANCE',
                        image: PreventiveMaintenance,
                        route: ''
                },
                {
                        name: 'TROUBLESHOOTING',
                        image: TroubleShooting,
                        route: ''
                },
                {
                        name: 'FUEL POLISHING',
                        image: FuelPolishing,
                        route: '/dieselalysis'
                },
                {
                        name: 'PAINT & CORROSION TREATMENT',
                        image: PaintCorrosionTreatment,
                        route: ''
                },
                {
                        name: 'MAINTENANCE PLANS',
                        image: MaintenancePlans,
                        route: ''
                }
        ]

        const deckCard = (card, id) => {
                return <div key={`${card.name}-${id}`} className="servicesCard d-flex flex-column align-items-center mx-2 mx-md-0 flex-grow  mb-5">
                        <div className="img-wrap mb-2 d-block px-4">
                                <img className='w-100' src={card.image} alt="" />
                        </div>
                        <div className="title-wrap mt-4">
                                <p className='mb-0  text-white text-center fw-bold '>{card.name}</p>
                        </div>
                </div>
        }

        return ( <div className='services-deck d-flex justify-content-evenly justify-content-lg-between  flex-wrap  w-auto pe-0 pe-lg-4'>

                {!isEmpty(servicesDeck) &&  servicesDeck.map((card,id) => 
                        card.route === '' ? deckCard(card, id): <Link to={`${card.route}`} className="text-decoration-none">{deckCard(card, id)}</Link>
                )}

        </div>
        )
}

import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { hideHomePopUp } from 'redux/features/ui/uiSlice';
import { getTomorrow, trackOnClick } from 'utils';

const DieselalysisFooter = () => {
    const dispatch = useDispatch();

    const [hideTilTomorrow, setHideTilTomorrow] = useState(false);

    const handleChangeCheckBox = () => {
        setHideTilTomorrow(!hideTilTomorrow);
    }

    const handleCloseModal = () => {
        dispatch(hideHomePopUp())
        if (hideTilTomorrow) document.cookie=`dsls-modal=seen; expires=${getTomorrow()}`
    };

    return (
        <div className='dieselalysis-footer'>
            <div className="btn-wrap">
                <div role="button" onClick={()=>handleCloseModal()} className="dsls-btn gray w-100 text-center fw-600">Skip</div>
                <div role="button" onClick={()=>handleCloseModal()} className="dsls-btn primary w-100 text-center fw-600">
                    <Link to="/dieselalysis" onClick={()=>trackOnClick()} className='text-white text-decoration-none'>Learn More</Link>
                </div>
            </div>
            <div className="modal-hide-counter py-3">
                <Form>
                        <div key={`dsls-checkbox`} >
                        <Form.Check 
                            className='xhours-hide'
                            type="checkbox"
                            id={`dsls-checkbox`}
                            label={`Don't show again for 24 hours.`}
                            checked={hideTilTomorrow}
                            onChange={()=>handleChangeCheckBox()}

                        />
                        </div>
                </Form>
            </div>
        </div>
    )
}

export default DieselalysisFooter
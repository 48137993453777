export const getTomorrow = () => {
    const d = new Date();
    const now_utc =  Date.UTC(
            d.getUTCFullYear(), 
            d.getUTCMonth(), 
            d.getUTCDate() + 1, 
            d.getHours(), 
            d.getUTCMinutes(), 
            d.getUTCSeconds());
    return new Date(now_utc);
}  
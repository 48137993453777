import React, { useCallback, useState } from 'react'
import ImageViewer from 'react-simple-image-viewer';

import GallerySquare1 from 'resources/images/general/Dieselalysis/gallery-square-01.jpg';
import GallerySquare2 from 'resources/images/general/Dieselalysis/gallery-square-02.jpg';
import GallerySquare3 from 'resources/images/general/Dieselalysis/gallery-square-03.jpg';
import GallerySquare4 from 'resources/images/general/Dieselalysis/gallery-square-04.jpg';
import GalleryTower1 from 'resources/images/general/Dieselalysis/gallery-tower-01.jpg';


const MenAtWork = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const images = [
        GallerySquare1,
        GallerySquare2,
        GallerySquare3,
        GallerySquare4,
        GalleryTower1
    ];

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);
    
      const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };

  return (
    <>
        <div id="men-at-work" className='container-lg p-4'>
            <div className="w-100">
                <div className="title-wrap">
                    <h4>
                        <span  className="text-tradesmith-stamp text-white big-title-stamp">Men </span>
                        <span  className="text-tradesmith-stamp text-primary big-title-stamp">at work</span>
                    </h4>
                </div>
                <div className="tiles-container d-flex flex-wrap justify-content-between flex-md-nowrap ">
                    <div className="order-1 d-flex flex-column column column-1 d-flex flex-column">
                        <img onClick={ () => openImageViewer(0) } key={ 0 } src={GallerySquare1} className='tile img-fluid ' alt="" />
                        <img onClick={ () => openImageViewer(1) } key={ 1 } src={GallerySquare2} className='tile img-fluid' alt="" />
                    </div>
                    <div className="order-3 order-sm-2 d-flex flex-row flex-sm-column column column-2">
                        <img onClick={ () => openImageViewer(2) } key={ 2 } src={GallerySquare3} className='tile img-fluid ' alt="" />
                        <img onClick={ () => openImageViewer(3) } key={ 3 } src={GallerySquare4} className='tile img-fluid' alt="" />
                    </div>
                    <div className="order-2 order-sm-3 column column-3 ">
                        <img onClick={ () => openImageViewer(4) } key={ 4 } src={GalleryTower1} className='tile img-fluid' alt="" />
                    </div>
                </div>
            </div>

        </div>

        {isViewerOpen && (
        <div className="dsls-image-viewer"> <ImageViewer
          src={ images }
          currentIndex={ currentImage }
          disableScroll={ true }
          backgroundStyle={{backgroundColor:'rgba(0,0,0,.9)', zIndex:100}}
          closeOnClickOutside={ true }
          onClose={ closeImageViewer }
        />
        </div>
      )}
    </>
  )
}

export {MenAtWork}
import React from 'react'
import maintenacePromo from 'resources/images/general/promo10.jpg';
export const Promo = () => {
  return (
    <div className="promo container-lg p-4 row d-flex align-items-center flex-column-reverse flex-md-row">
      <div className="left col-12 col-md-6 px-0 p-sm-0 pe-md-5 mb-0 mt-5 mt-md-0">
          <h2 className='fs-3 text-primary m-0 mb-0 mb-lg-2'>What's new?</h2>
          <h2 className='h1 fst-italic text-roboto-condensed fw-900 big-text text-gray'>Hurricane Season?</h2>
          <h2 className='h1 pull-top fst-italic text-roboto-condensed mb-4 fw-900 big-text text-gray'>Power Outage?</h2>
          <p className='text-white fs-5 text-primary'>Save up to 10% OFF! when you avail our Trimestral Maintenance Plan. Worry no more of unexpected costs, and plenty of time wasted.</p>
          <h6 className='text-primary mt-0 mt-md-5 mb-5 mb-md-0'>*Promo valid to new customers only.</h6>
      </div>
      <div className="right promo-img-wrap  col p-0 promo-img">
          <img src={maintenacePromo} className="img-fluid w-100" alt="" />
      </div>
  </div>
   
  )
}

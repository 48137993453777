import React from 'react'
import { trackOnClick } from 'utils'

const DieselalysisExperience = () => {
  return (
    <>
        <div id="dieselalysis-experience" className='container-lg p-4'>
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="left column ">
                    <p className="medium-text fw-500 text-white">
                        <span className="text-primary"> Experience </span> Dieselalysis™, to have healthy fuel system, or get the full line of our services and save up to 10%. Contact your nearest DieselTech Service Specialist now!
                    </p>
                </div>
                <div className="right column">
                    <a href="https://drive.google.com/uc?export=download&id=163qgI9sJaJ0qbErgWcnU8-7eUQSGraEz" className="px-5 py-3 bg-white d-flex align-items-center justify-content-center text-decoration-none mb-2">
                        <h4 className='mb-0 fw-600 text-nowrap text-black'>Download Free Flyer</h4>
                    </a>

                    <a href="tel:787-679-2526" onClick={()=>trackOnClick()} className="px-5 py-3 bg-primary d-flex  justify-content-center text-decoration-none">
                        <h4 className='mb-0 fw-600 text-nowrap text-black'>Talk To A Specialist</h4>
                    </a>
                </div>
            </div>
        </div>
    </>
  )
}

export {DieselalysisExperience}
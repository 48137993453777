import { isEmpty } from 'lodash'
import React from 'react'

export const CoreValues = () => {

        const values = [
                {
                        class: "yellow",
                        name: 'Customer Experience'
                },
                {
                        class: "bg-white",
                        name: 'Trustworthiness'
                },
                {
                        class: "bg-white",
                        name: 'Integrity'
                },
                {
                        class: "yellow",
                        name: 'Accountability'
                },
        ];

        return (
                <div className='container-fluid core-values d-flex flex-wrap justify-content-between px-0'>
                        {!isEmpty(values) && values.map((value, id) => 
                                <div key={`${value.name}-${id}`} className={`core p-4 mb-4 rounded d-flex align-items-center ${value.class}`}>
                                        <div className="number rounded-circle text-white d-flex align-items-center justify-content-center fw-600 fs-3">{id + 1}</div>
                                        <div className="name text-roboto-condensed fst-italic fw-900 fs-3">{value.name}</div>
                                </div>
                        )}
                </div>
        )
}

import React from 'react'
import DFuel from 'resources/images/general/diesel-fuel-dieselalysis.png';

const DieselFuel = () => {
  return (
    <>
        <div id="diesel-fuel" className='container-lg  p-4 '>
            <div className="col-12 border px-4 py-4 border-primary">
                <div className="row">
                    <div className="left col-12 col-md-6  d-flex justify-content-center align-items-center">
                        <img src={DFuel} className={'d-fuel-flask img-fluid'} alt="diesel fuel" />
                    </div>
                    <div className="right col-12 col-md-6 py-2 ps-md-4 d-flex align-items-center">
                        <p className='text-white medium-text fw-500 mb-0'> <span className='text-primary'>Diesel Fuel</span>, is your generators blood.It powers your engine, but after a long period of operation, it starts to get cloudy and dirty. Eventually, forming unwanted matters inside the engine.</p>
                    </div>
                </div>
            </div>
            <div className="bg-primary p-2">
                <div className="row">
                    <div className="col-12 col-md-6"></div>
                    <div className="col-12 col-md-6 p-2 ps-4">
                        <h5 className='df-text fw-600 lh-1 mb-0 text-black'>WORST CASE: TANK CONTAMINATION</h5>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export {DieselFuel}
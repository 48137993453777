import {Hero} from 'components/_ui/hero';
import { Promo } from 'components/_ui/promo/';
import { Services } from 'components/_ui/services';
import { WhatsApp } from 'components/_ui/whatsapp';
import {Values} from 'components/_ui/values';
import React, {  useEffect, useState } from 'react'
import { BlazingPassion } from 'components/_ui/blazing-passion';
import { Waypoint } from 'react-waypoint';
import FlyWeird from "resources/images/svgs/flyweird.svg";
import { Dieselalysis } from 'components/_common/modals';
import { hideHomePopUp, showHomePopUp } from 'redux/features/ui/uiSlice';
import { useDispatch } from 'react-redux';

const Home = () => {
  const dispatch = useDispatch();

  const [showFlyWeird, setShowFlyWeird] = useState(false);
  const onEnterServices = () => {setShowFlyWeird(true)}
  const onEnterPromo = () => {setShowFlyWeird(false)}
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  }

  useEffect(()=>{
    if (document.cookie.indexOf('dsls-modal') >= 0) {
      dispatch(hideHomePopUp())
    } else {
      dispatch(showHomePopUp())
    }
  },[dispatch])

  return (
    <div className='page'>
      {showFlyWeird && <div className="flyweird w-100 d-flex ">
        <div className="w-100 container-lg d-flex justify-content-end">
          <div onClick={()=>handleScrollToTop()} className="flyweird-btn-wrap">
            <img src={FlyWeird} alt="" /></div>
        </div>
      </div>}``
      <div id='hero' className="hero-wrap w-100">
        <Hero/>
      </div>
      <div id='promo' className='promo-wrap d-flex justify-content-center w-100 pt-0 pt-md-5'>
        <Promo/>
      </div>
      <div id='whatsApp' className='d-flex justify-content-center w-100 pt-0 pt-md-5'>
        <WhatsApp/>
      </div>
      <div id='services' className='d-flex justify-content-center w-100 pt-0 pt-md-5'>
        <Services/>
      </div>
      <div id='values' className="d-flex justify-content-center w-100 pt-0 pt-md-5">
        <Values />
      </div>
      <div id='blazingPassion' className="d-flex justify-content-center w-100 pt-0 pt-md-5">
        <BlazingPassion />
      </div>
      <Waypoint onEnter={()=>onEnterServices()} onLeave={()=>onEnterPromo()} />

      {/* Homepage pop up modal */}
      <Dieselalysis />
    </div>
  )
}

export default Home
import React from 'react'
import Whatsapp from 'resources/images/general/whatsApp.jpg';
import WhatsappMobile from 'resources/images/general/whatsAppMobile.jpg';
import { trackOnClick2 } from 'utils/eventTracker';

export const WhatsApp = () => {
  return (
    <div role={'button'} className="container-lg right p-4 mb-5 mb-md-0">
      <a onClick={()=>trackOnClick2()} href="https://wa.me/17876792526?text=Hi%20DTS%20Team,%20I'm%20interested%20on%20your%20services." target="_blank" rel="noreferrer">
        <img src={Whatsapp} className="img-fluid w-100 d-none d-md-block" alt="iphone mockup with whatsapp logo in green" />
        <img src={WhatsappMobile} className="img-fluid w-100 d-block d-md-none" alt="iphone mockup with whatsapp logo in green" />
      </a>
    </div>
  )
}

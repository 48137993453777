import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons'
import { dtsContent } from 'contents'
import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import { trackOnClick } from 'utils';
const Hero = () => {
  return (
    <div className='hero col-12 d-flex flex-column justify-content-center align-items-center'>
        <div className="container-xl ">
            <div className="cta px-3 px-md-0">
                <div className="title text-center text-tradesmith-stamp d-block">
                    <h1 className='text-reset lh-1'><span className="text-white">ATLEAST, </span><span className='text-primary'>YOU HAVE BACKUP</span></h1>
                </div>
                <div className="title-sub text-center d-block text-white mt-4">
                    <h2 className='fs-2'>{dtsContent.heroSub}</h2>
                </div>
                <div className="btn-wrap d-flex justify-content-center mt-t  mt-lg-5 pt-5 pt-lg-3 mb-3 mb-lg-0 ">
               
               <a onClick={()=>trackOnClick()} href="tel:787-679-2526" type="button"><button className="btn btn-primary btn-lg btn-rounded text-roboto-condensed fst-italic fw-bold  fs-3 fs-sm-4 px-5 py-3">GET SECURED!</button></a>
                </div>
            </div>
           
        </div>
        <div className="scroll-down-wrap w-100 d-none d-sm-flex text-white align-items-center justify-content-center w-100 mb-3">
            <div role='button' className='px-5 mb-5 shake-vertical'>
                <Link to="#promo">
                    <FontAwesomeIcon icon={faAnglesDown} className="fs-4 text-white"  />
                </Link>
            </div>
        </div>
    </div>
  );
}

export default Hero
import React from 'react'
import { CoreValues } from './CoreValues'

export const Values = () => {
  return (
    <div className='values container-lg p-4 mb-5 mb-md-0'>
        <h2 className='fs-3 text-primary m-0 mb-2'>Our Values</h2>
        <div className="content-block d-flex flex-column flex-lg-row mt-5">
          <div className="left w-100 ">
            <CoreValues/>
          </div>
          <div className="right w-100 ps-0 ps-lg-5 mt-4 mt-lg-0">
            <p className='text-white fs-5'>Here at DTS, we share our passion, a mission to see each customer satisfied for every project we deliver. The feedback we receive, brings innovation into our methods, they contributed to our humble beginning.</p>
            <p className='text-white fs-5'>Day to day power outages will always be there, this is why we remain committed in bringing the right solution to the community.</p>
          </div>
        </div>
    </div>
  )
}

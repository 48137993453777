import React from 'react'
import Dieselalysis from 'resources/images/svgs/dieselalysis/dieselalysis-symbol.svg';
import DAPaintUnderline from 'resources/images/svgs/dieselalysis/scratch.svg';

const GeneratorLife = () => {
  return (
    <>
        <div id="generator-life" className='w-100 container-lg d-flex justify-content-center align-items-center flex-column flex-md-row p-4'>
            <div className="da-logo w-auto me-0 me-md-5 mb-4 mb-md-0">
              <img src={Dieselalysis} alt="Dieselalysis logo" />
            </div>
            <div className="w-100 text-center pl-0 pl-md-4">
              <h2 className='text-tradesmith-stamp primary'>
                <span className='text-primary da-big-text'>LONGER GENERATOR LIFE </span>
                <span className='text-white da-big-text' >WITH </span>
                <span className='text-white da-big-text emphasized'>DIESELALYSIS<img className='paint-underline' src={DAPaintUnderline} alt="paint underline" /> </span></h2>
            </div>
        </div>
    </>
  )
}

export {GeneratorLife}
import { CustomModal } from 'components/_ui/cx-modal'
import React, { useState } from 'react'
import DieselalysisFooter from './DieselalysisFooter'
import DieselalysisModal from 'resources/images/general/dieselalysisModal.jpg'
import { useSelector } from 'react-redux'


const Dieselalysis = () => {

    const modalShow = useSelector(state => state.ui.showHomePopUp);

    const [show, setShow] = useState(true);

    const handleCloseModal = () => {
        setShow(!show);
    }

    
    const dieselalysisModalImg = <img src={DieselalysisModal} className={'img-fluid'} alt="dieselalysis"/>
    
    return (
        <div>
            <CustomModal
                show={modalShow} 
                content={dieselalysisModalImg} 
                close={()=>handleCloseModal()} 
                className={'cx-modal-dieselalysis'} 
                footer={<DieselalysisFooter/>} 
                noHeader={true}
                centered
                backdropClassName="dsls-modal-backdrop"
            />
        </div>
    )
}

export default Dieselalysis
import React, { useCallback, useRef } from 'react'
import leftArrow from "resources/images/svgs/ui-elements/left-arrow.svg";
import rightArrow from "resources/images/svgs/ui-elements/right-arrow.svg";

// import Swiper core and required modules
import SwiperCore, { 
    Navigation, 
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

SwiperCore.use([Navigation]);

const Slide = (props) => {

    const {className, slides, nav = true, ...restOfProps} = props;

    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);


    return (
    <>
        <div className="dts-slide">
            <div className={'d-flex align-items-center justify-content-between '+ className}>
                {nav && <div className="prev-arrow d-none d-md-block me-2 me-md-4" onClick={handlePrev} >
                        <div className="nav-round">
                            <img src={leftArrow} className="arrow arrow-left" alt="left" />
                        </div>
                    </div>}
                <Swiper {...restOfProps} ref={sliderRef}>
                    {slides.length > 0 && slides.map(slide => {
                        return <>
                            <SwiperSlide>
                                {slide}
                            </SwiperSlide>
                        </>
                    })}
                </Swiper>
                {nav && <div className="next-arrow d-none d-md-block ms-2 ms-md-4" onClick={handleNext} >
                            <div className="nav-round">
                                <img src={rightArrow} className="arrow arrow-right" alt="right" />
                            </div>
                        </div>}
            </div>

            <div className="mobile-arrows d-flex align-items-center justify-content-center w-100 d-md-none mt-4">
                <div className="nav-round mx-2" onClick={handlePrev} >
                    <img src={leftArrow} className="arrow arrow-left" alt="left" />
                </div>
                <div className="nav-round mx-2" onClick={handleNext} >
                    <img src={rightArrow} className="arrow arrow-right" alt="right" />
                </div>
            </div>
        </div>
    </>
  )
}



export {Slide}
import React from 'react'
import { Deck } from './Deck'
import dieselalysis from 'resources/images/general/dieselalysis.jpg'
import dieselalysisMobile from 'resources/images/general/dieselalysisMobile.jpg'
import { trackOnClick } from 'utils'
import { Link } from 'react-router-dom'

export const Services = () => {
  return (
    <div className="container-lg right p-4 w-100 mb-5 mb-md-0">
      <div className='d-flex justify-content-center justify-content-lg-start'>
        <h2 className='text-primary'>Our Services</h2>
      </div>
      
      <div className='decks d-flex flex-column flex-lg-row  mb-4 mb-lg-0  w-100 mt-5'>
        <div className="left w-100">
          <Deck/>
        </div>
      </div>

      <Link to="/dieselalysis">
        <div className="dieselalysis">
          <img src={dieselalysis} className="img-fluid d-none d-md-block" alt="grey clouds with 3 small yellow lightning" />
          <img src={dieselalysisMobile} className="img-fluid d-block d-md-none w-100" alt="grey clouds with 3 small yellow lightning" />
        </div>
      </Link>
      
      <div className="residential-industrial d-flex flex-column flex-lg-row mt-5 pt-3">

        <div className="left ">
          <h2 className='h1 text-roboto-condensed fst-italic fw-900 big-text-2 lh-1'><span className='text-gray text-nowrap'>RESIDENTIAL &amp;</span> <br /> <span className='text-primary'>INDUSTRIAL</span></h2>
        </div>

      <div className='d-flex flex-column flex-md-row'>
        <div className="middle px-0 pe-md-5 ps-lg-5">
          <p className='text-white mb-0'>We do marine works also in our line up. Check our technicians ready to take your call. Let us know your concerns, we are geared to help! we’ll take care of that messy tasks for you!</p>
        </div>

        <div className="right col-md-4 d-block">
          <a href="tel:787-679-2526" onClick={()=>trackOnClick()}role={'button'} className="get-estimate bg-warning  cta-large fst-italic fw-900 text-roboto-condensed text-nowrap rounded-0 d-flex justify-content-center align-items-center px-4 py-0 py-md-4 mt-4 mt-md-0  w-100 text-default text-decoration-none" >GET ESTIMATE</a>
        </div>
      </div>

      </div>
    </div>
  )
}

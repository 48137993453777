import { 
  Benefits, 
  // CustomerFeedBack, 
  DieselalysisExperience, 
  DieselFuel, 
  FuelRecycle, 
  GeneratorLife, 
  MenAtWork 
} from 'components/_page-components'
import React from 'react'

const Dieselalysis = () => {
  return (
    <>
      <div id="dieselalysis-page">
        <GeneratorLife/>
        <DieselFuel/>
        <FuelRecycle/>
        <Benefits/>
        {/* <CustomerFeedBack/> */}
        <MenAtWork/>
        <DieselalysisExperience/>
      </div>
    </>
  )
}

export {Dieselalysis}
import { dtsContent } from 'contents'
import React from 'react'

const Ribbon = () => {
  return (
    <div id="ribbon" className='ribbon col-12 bg-warning py-3 px-4'>
        <div className="text-center text-uppercase fw-bold">
        <h4 className='text-sm-small mb-0'>{dtsContent.ribbonText}</h4></div>
    </div>
  )
}

export default Ribbon;
export const linkList = [
// {
//     label: "DIESELALYSIS",
//     path: "/dieselalysis"
// },
{
    label: "HOT! OFFER",
    path: "/#promo"
},
{
    label: "DIESELALYSIS™",
    path: "/dieselalysis#dieselalysis-page"
},
{
    label: "OUR SERVICES",
    path: "/#services"
},
{
    label: "OUR VALUES",
    path: "/#values"
},
{
    label: "DIESEL JUNKIE™",
    path: "/#blazingPassion"
},
{
    label: "CONTACT US",
    path: "/#whatsApp"
},
{
    label: "HOME",
    path: "/#ribbon"
},]

export const dieselalysisFlyerMenu = [{
    label: "Longer Generator Life",
    path: "#longer-life"
}, {
    label: "Diesel Fuel",
    path: "#diesel-fuel"
}, {
    label: "Dieselalysis",
    path: "#dieselalysis"
}, {
    label: "Benefits",
    path: "#benefits"
}, {
    label: "Feedback",
    path: "#dieselalysis-feedback"
}, {
    label: "Men at Work",
    path: "#menAtWork"
}, {
    label: "Experience",
    path: "#experience"
}]